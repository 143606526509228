import React, { useState } from "react";
import { IconContext } from "react-icons";
import { TiShoppingCart } from "react-icons/ti";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { search } from "../../services/search";
import { Loader } from "react-loader-spinner";

const Toolbar = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  return (
    <div className="x-sm:space-y-6 h-full md:px-20 x-sm:py-6 md:py-2 flex x-sm:flex-col md:flex-row items-center justify-between">
      <div>
        <div className="">
          <img src="/images/store-logo.png" alt="Logo" className="w-48" />
        </div>
      </div>
      <div className="md:mr-64">
        <div class="flex shadow-2xl">
          <button
            onClick={async () => {
              setLoading(true);
              let res = await search(query);
              if (res.status === 200) {
                if (res.data.results.length > 0) {
                  // setLoading(false);
                  history.push("/results", { results: res.data.results });
                } else {
                  // setLoading(false);
                  alert("ما تبحث عنه غير موجود ❌");
                }
              } else {
                // setLoading(false);
                alert("something wrong,retry");
              }
            }}
            className="text-xs md:text-sm rounded-l-xl px-4 py-2 bg-reghda-blue whitespace-no-wrap outline-none focus:outline-none"
          >
            <IconContext.Provider
              value={{ size: "2em", style: { color: "#fff" } }}
            >
              <AiOutlineSearch></AiOutlineSearch>
            </IconContext.Provider>
          </button>
          <input
            name="field_name"
            className="rounded-r-xl x-sm:px-2 x-sm:py-1 md:px-4 md:py-2 2xl:px-6 2xl:py-4 w-full text-right text-gray-600 x-sm:text-xs lg:text-lg 2xl:text-2xl outline-none focus:outline-none"
            type="text"
            placeholder="بحث"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {/* {loading && (
            <Loader type="Oval" height={50} width={50} color="#91b6c6" />
          )} */}
        </div>
      </div>
      <Link
        to="/cart"
        className="hidden md:flex flex-row text-gray-700 font-bold items-center space-x-2 2xl:space-x-4 text-center x-sm:text-xs md:text-lg 2xl:text-2xl"
      >
        <div className="bg-reghda-gray p-2 rounded-xl">
          <IconContext.Provider
            value={{ size: "2em", style: { color: "#fff" } }}
          >
            <TiShoppingCart></TiShoppingCart>
          </IconContext.Provider>
        </div>
        <p>سلة المشتريات</p>
      </Link>
    </div>
  );
};

export default Toolbar;
