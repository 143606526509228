import React, { useState } from "react";
import useSWR, { mutate, trigger } from "swr";
import { fetcher } from "./../../services/fetcher";
import Rating from "@material-ui/lab/Rating";
import {
  addComment,
  deleteComment,
  editComment,
} from "../../services/productComments";
import { CgMenuMotion } from "react-icons/cg";
import { getTheUserFromStorage } from "./../../services/auth";
import {
  addResponseReview,
  deleteResponseReview,
  editResponseReview,
} from "../../services/reviews";

const Reviews = ({ productId, isLogged, alreadyPaid, setLoading }) => {
  const [detail, setDetail] = useState("");
  const [openEditComment, setOpenEditComment] = useState(false);
  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState("");
  const [response, setResponse] = useState("");
  const [respondComment, setRespondComment] = useState(null);
  const [editRespondComment, setEditRespondComment] = useState(null);
  const [toggleMenu, setToggleMenu] = useState(null);

  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/reviews/products/?product=${productId}`,
    fetcher
  );

  const { data: isCommented } = useSWR(
    isLogged
      ? `https://ragdasairafi369.smartiniaclient.com/reviews/has-permission?user=${
          getTheUserFromStorage().user_id
        }&product=${productId}`
      : null,
    fetcher
  );

  return (
    <div className="py-6  w-full md:w-8/12 mx-auto">
      <div className="text-right text-base text-gray-600   x-sm:leading-loose md:leading-loose  lg:leading-loose  2xl:leading-loose w-11/12 mx-auto">
        <h1 className="text-reghda-blue  text-center text-2xl lg:text-4xl 2xl:text-5xl  mb-4">
          آراء المشتريين السابقين
        </h1>

        {data && data.length === 0 && (
          <div className="h-36 flex items-center justify-center">
            <p className="text-base sm:text-2xl md:text-4xl">لا يوجد تعليقات</p>
          </div>
        )}
        {data &&
          data.map((item) => (
            <div key={item.id} className="border-bottom-grey">
              <div className="py-8 flex flex-col-reverse items-end justify-end space-x-3">
                <div className="flex flex-30 items-center mt-8 space-x-3">
                  <button
                    className="bg-reghda-pink text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-100"
                    onClick={async () => {
                      if (respondComment !== item.id) {
                        setRespondComment(item.id);
                      } else {
                        setLoading(true);
                        setLoading(true);
                        const res = await addResponseReview({
                          user: getTheUserFromStorage().user_id,
                          parent: item.id,
                          body: response,
                        });
                        mutate(
                          `https://ragdasairafi369.smartiniaclient.com/reviews/products/?product=${productId}`
                        );
                        setResponse("");
                        setRespondComment(null);
                        setLoading(false);
                      }
                    }}>
                    {respondComment === item.id
                      ? "تأكيد الرد"
                      : "الرد على التعليق"}
                  </button>
                  {isLogged &&
                    item.user.id === getTheUserFromStorage().user_id && (
                      <>
                        <button
                          className="bg-reghda-pink text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-100"
                          onClick={async () => {
                            setLoading(true);
                            const res = await deleteComment(item.id);
                            mutate(
                              `https://ragdasairafi369.smartiniaclient.com/reviews/products/?product=${productId}`,
                              data.filter((product) => product.id !== item.id),
                              false
                            );
                            trigger(
                              `https://ragdasairafi369.smartiniaclient.com/reviews/has-permission?user=${
                                getTheUserFromStorage().user_id
                              }&product=${productId}`
                            );
                            setDetail("");
                            setRating(0);
                            setTitle("");
                            setLoading(false);
                          }}>
                          حدف
                        </button>
                        <button
                          onClick={async () => {
                            if (!openEditComment) {
                              setTitle(item.title);
                              setDetail(item.comment);
                              setRating(item.rating);
                              setOpenEditComment(!openEditComment);
                            } else {
                              setLoading(true);
                              const res = await editComment({
                                commentId: item.id,
                                detail: detail,
                                rating: rating,
                                title: title,
                              });
                              mutate(
                                `https://ragdasairafi369.smartiniaclient.com/reviews/products/?product=${productId}`
                              );
                              setRating(0);
                              setTitle("");
                              setDetail("0");
                              setOpenEditComment(false);
                              setLoading(false);
                            }
                          }}
                          className="bg-reghda-pink text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-100">
                          {openEditComment ? "تأكيد" : "تعديل"}
                        </button>
                      </>
                    )}
                </div>
                <div className="w-full ">
                  {!openEditComment && (
                    <p className="x-sm:text-base  lg:text-2xl mt-4">
                      {item.comment}
                    </p>
                  )}
                  {item.answers.length > 0 && (
                    <div className="mx-8 mt-4">
                      <h1 className="font-bold">: الردود على التعليق</h1>
                      {item.answers.map((answer) => (
                        <div className="border-bottom-grey mt-4">
                          <div
                            className={`flex mb-4 ${
                              answer.user.id === getTheUserFromStorage().user_id
                                ? "justify-between"
                                : "justify-end"
                            }`}>
                            {answer.user.id ===
                              getTheUserFromStorage().user_id && (
                              <div className="relative">
                                <button
                                  onClick={() => {
                                    if (toggleMenu !== answer.id) {
                                      setToggleMenu(answer.id);
                                    } else {
                                      setToggleMenu(null);
                                    }
                                  }}>
                                  <CgMenuMotion className="h-3 w-3 md:w-6 md:h-6 outline-none focus:outline-none " />
                                </button>
                                {toggleMenu === answer.id && (
                                  <div className="absolute flex flex-col ">
                                    <button
                                      onClick={async () => {
                                        setLoading(true);
                                        await deleteResponseReview(answer.id);
                                        mutate(
                                          `https://ragdasairafi369.smartiniaclient.com/reviews/products/?product=${productId}`
                                        );
                                        setLoading(false);
                                      }}
                                      className="text-xs md:text-base p-2 bg-reghda-black  text-white rounded-t-lg">
                                      حدف
                                    </button>
                                    <button
                                      onClick={async () => {
                                        if (editRespondComment !== answer.id) {
                                          setResponse(answer.body);
                                          setEditRespondComment(answer.id);
                                        } else {
                                          setLoading(true);
                                          const res = await editResponseReview({
                                            responseId: answer.id,
                                            body: response,
                                          });
                                          mutate(
                                            `https://ragdasairafi369.smartiniaclient.com/reviews/products/?product=${productId}`
                                          );
                                          setResponse("");
                                          setToggleMenu(false);
                                          setEditRespondComment(null);
                                          setLoading(false);
                                        }
                                      }}
                                      className="text-xs md:text-base p-2 bg-reghda-black  text-white rounded-b-lg">
                                      {editRespondComment === answer.id
                                        ? "تأكيد"
                                        : "تعديل"}
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="flex justify-end items-center space-x-4 space-y-2">
                              <span className="text-xs md:text-sm font-bold">
                                {answer.user.family_name}{" "}
                                {answer.user.given_name}
                              </span>
                              <img
                                src={
                                  answer.user.image
                                    ? answer.user.image
                                    : "/images/admin/default-user.png"
                                }
                                alt="profile picture"
                                className="rounded-full x-sm:w-6 x-sm:h-6  md:w-8 md:h-8"
                                style={{ margin: "0 0 0 0.5rem " }}
                              />
                            </div>
                          </div>

                          <div className={`flex mb-4 justify-end`}>
                            {editRespondComment !== answer.id ? (
                              <span className="text-xs md:text-sm">
                                {answer.body}
                              </span>
                            ) : (
                              <div className="space-y-4 mt-4">
                                <div className="w-full  mb-4">
                                  <span className="text-xl">
                                    : الرد على التعليق
                                  </span>
                                  <textarea
                                    onChange={(event) =>
                                      setResponse(event.target.value)
                                    }
                                    value={response}
                                    placeholder="الرد على التعليق"
                                    rows="3"
                                    id="text"
                                    type="text"
                                    class="w-full border-2 border-gray-200 bg-white text-right text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {respondComment === item.id && (
                    <div className="space-y-4 mt-4">
                      <div className="w-full  mb-4">
                        <span className="text-xl">: الرد على التعليق</span>
                        <textarea
                          onChange={(event) => setResponse(event.target.value)}
                          value={response}
                          placeholder="الرد على التعليق"
                          rows="3"
                          id="text"
                          type="text"
                          class="w-full border-2 border-gray-200 bg-white text-right text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                        />
                      </div>
                    </div>
                  )}

                  {isLogged &&
                    item.user.id === getTheUserFromStorage().user_id &&
                    openEditComment && (
                      <div className="space-y-4">
                        <div className="flex items-center justify-end space-x-2 space-y-4">
                          <Rating
                            name="simple-controlled"
                            value={rating}
                            onChange={(event, newValue) => {
                              setRating(newValue);
                            }}
                          />
                          <span className="text-xl ">تقييم المنتج</span>
                        </div>

                        <div className="w-full  mb-4">
                          <span className="text-xl">: عنوان المراجعة</span>
                          <input
                            onChange={(event) => setTitle(event.target.value)}
                            value={title}
                            className="bg-white w-full   text-right x-sm:p-1 md:p-2 2xl:p-4  rounded-2xl outline-none focus:outline-none  focus:border-pink-300 border-1"
                            placeholder="عنوان"
                          />
                        </div>

                        <div className="w-full  mb-4">
                          <span className="text-xl">: تفاصيل المراجعة</span>
                          <textarea
                            onChange={(event) => setDetail(event.target.value)}
                            value={detail}
                            placeholder="تعليق"
                            rows="3"
                            id="text"
                            type="text"
                            class="w-full border-2 border-gray-200 bg-white text-right text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                          />
                        </div>
                      </div>
                    )}
                </div>
                <div className=" flex flex-col items-center justify-center space-y-2 space-x-4">
                  <div className="flex items-center space-x-4">
                    <div>
                      <p
                        style={{ margin: 0 }}
                        className="text-center   font-extrabold x-sm:text-sm sm:text-base ">{`${item.user.given_name} ${item.user.family_name}`}</p>
                      <p className="x-sm:text-sm sm:text-sm">
                        {item.created_at.slice(0, 10)}
                      </p>
                    </div>
                    <img
                      style={{ marginTop: 0 }}
                      className="rounded-full x-sm:w-8 x-sm:h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 2xl:w-16 2xl:h-16 picfit"
                      alt="user"
                      src={
                        item.user.image
                          ? item.user.image
                          : "/images/admin/default-user.png"
                      }
                    />
                  </div>
                  {!openEditComment && (
                    <div className="flex justify-end space-x-4 ">
                      <p style={{ margin: 0 }}>{item.title}</p>
                      <div className="space-x-4 flex items-center ">
                        <Rating readOnly value={item.rating} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>

      {isLogged && isCommented && (
        <div className="pt-4 pb-20 text-right text-base text-gray-600   x-sm:leading-loose md:leading-loose  lg:leading-loose  2xl:leading-loose w-11/12 mx-auto">
          <div className="py-8 flex flex-row items-center justify-items-center space-x-3">
            <div className="w-1/5">
              <div className="flex flex-row items-center justify-center">
                <button
                  onClick={async () => {
                    if (detail.length < 1) {
                      return;
                    }
                    setLoading(true);
                    let res = await addComment({
                      user: getTheUserFromStorage().user_id,
                      product: productId,
                      rating: rating,
                      comment: detail,
                      title: title,
                    });
                    if (res && res.status === 201) {
                      mutate(
                        `https://ragdasairafi369.smartiniaclient.com/reviews/products/?product=${productId}`
                      );
                    } else {
                      alert("something wrong!");
                    }
                    setDetail("");
                    setTitle("");
                    setRating(0);
                    trigger(
                      `https://ragdasairafi369.smartiniaclient.com/reviews/has-permission?user=${
                        getTheUserFromStorage().user_id
                      }&product=${productId}`
                    );
                    setOpenEditComment(false);
                    setLoading(false);
                  }}
                  className="bg-reghda-pink text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-100">
                  أضف
                </button>
              </div>
            </div>
            <div className="space-y-4 w-4/5">
              <div className="flex items-center justify-end space-x-2 ">
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
                <span className="text-xl">تقييم المنتج</span>
              </div>

              <div className="w-full  mb-4">
                <span className="text-xl">: عنوان المراجعة</span>
                <input
                  onChange={(event) => setTitle(event.target.value)}
                  value={title}
                  className="bg-white w-full border-1  text-right x-sm:p-1 md:p-2 2xl:p-4  rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
                  placeholder="عنوان "
                />
              </div>

              <div className="w-full  mb-4">
                <span className="text-xl">: تفاصيل المراجعة</span>
                <textarea
                  onChange={(event) => setDetail(event.target.value)}
                  value={detail}
                  placeholder="تعليق"
                  rows="3"
                  id="text"
                  type="text"
                  class="w-full border-2 border-gray-200 bg-white text-right text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reviews;
