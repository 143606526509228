import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const fadeImages = [
  "/images/slides/slide0.png",
  "/images/slides/banner-1.png",
  "/images/slides/banner-2.png",
  "/images/slides/banner-3.png",
];

const Show = () => {
  return (
    <div className="h-full x-sm:p-4 md:px-20 space-y-8">
      <div className="bg-reghda-blue rounded-lg w-full text-center text-white px-2 py-2 md:text-xl lg:text-2xl 2xl:text-4xl">
        <h1>متجر رغد الحب</h1>
      </div>
      <div className="w-full h-full">
        <Fade>
          {fadeImages.map((item) => (
            <div className="each-fade">
              <div className="h-full w-full flex flex-row items-center justify-center">
                <img className="h-full w-3/4" src={item} alt="hhh" />
              </div>
            </div>
          ))}
        </Fade>
      </div>
    </div>
  );
};

export default Show;
