import React, { useState, useEffect } from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import { useHistory } from "react-router-dom";
import LoaderModal from "./../LoaderModal";
import { getTheUserFromStorage } from "./../../services/auth";
import { verify } from "./../../services/pay";
import { createOrder } from "../../services/orders";
import { clearCart } from "../../services/cart";
import { toast } from "react-toastify";

const CheckOutVerifyPage = () => {
  let history = useHistory();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const ref = localStorage.getItem("currentPaymentRef");

  useEffect(() => {
    async function fetch() {
      let res = await verify({
        tran_ref: ref,
      });
      setData(res.data);
      return res;
    }
    ref && fetch();
  }, []);

  const onSuccess = async () => {
    const res = await createOrder({
      user: getTheUserFromStorage().user_id,
      payment_method: "مدى",
      given_name: data.response.shipping_details.firstname,
      family_name: data.response.shipping_details.lastname,
      country: data.response.shipping_details.country,
      address: data.response.shipping_details.addressOne,
      second_address: data.response.shipping_details.addressTwo,
      phone_number: data.response.shipping_details.phone,
    });
    if (res.status === 200) {
      toast("العملية تمت بنجاح 🎉");
      await clearCart(getTheUserFromStorage().user_id);
      history.push("/my-orders");
    } else {
      toast("العملية لم تتم بنجاح ❌");
    }
    localStorage.removeItem("currentPaymentRef");
  };

  if (data && data.response.payment_result.response_status === "A") {
    onSuccess();
  }

  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <div className="min-h-80 flex flex-row items-center justify-center pb-16">
          {!data && <LoaderModal modalIsOpen={true}></LoaderModal>}
          {data && data.response.payment_result.response_status !== "A" && (
            <div className="text-center flex flex-row items-center justify-center pt-6 x-sm:text-xl sm:text-3xl lg:text-5xl 2xl:text-6xl font-bold">
              <h1 className="text-gray-600">خطاء في الدفع ❌</h1>
            </div>
          )}
          {data && data.response.payment_result.response_status === "A" && (
            <div className="text-center flex flex-row items-center justify-center pt-6 x-sm:text-xl sm:text-3xl lg:text-5xl 2xl:text-6xl font-bold">
              <h1 className="text-gray-600">تم الدفع ✔</h1>
            </div>
          )}
        </div>
        <Contact></Contact>
        <Footer></Footer>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CheckOutVerifyPage;
