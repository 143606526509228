import React from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import Toolbar from "./../store-page/Toolbar";
import Products from "./../store-page/Products";

const StoreTypePage = ({ type }) => {
  return (
    <>
      <Navbar></Navbar>
      <Toolbar></Toolbar>
      <Products
        title={type === "men" ? "عطور الرجال" : "عطور النساء"}
        id={type === "men" ? 17 : 16}
      ></Products>
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
};

export default StoreTypePage;
