import React from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import ProductCard from "./../store-page/ProductCard";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import { getTheUserFromStorage } from "./../../services/auth";

const FavouritePage = () => {
  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/store/favourites/list/${
      getTheUserFromStorage().user_id
    }`,
    fetcher
  );

  return (
    <>
      <Navbar></Navbar>
      <div className="h-full x-sm:p-4 md:px-20 space-y-8">
        <div className="bg-reghda-blue rounded-lg w-full text-center text-white px-2 py-2 md:text-xl lg:text-2xl 2xl:text-4xl">
          <h1>مفضلاتي</h1>
        </div>
        {(!data || data.Favourites.length < 1) && (
          <div className="text-center flex flex-row items-center justify-center pt-6 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
            <h1 className="text-gray-600">فارغة</h1>
          </div>
        )}
        <div className="w-full h-full grid x-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
          {data &&
            data.Favourites.map((product) => (
              <ProductCard product={product}></ProductCard>
            ))}
          {/* {data &&
          data.products.map((product) => (
            <ProductCard product={product}></ProductCard>
          ))} */}
          {/* <ProductCard></ProductCard>
          <ProductCard></ProductCard> */}
        </div>
      </div>
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
};

export default FavouritePage;
