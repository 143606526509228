import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="x-sm:text-xxs md:text-xs lg:text-lg 2xl:text-2xl text-center text-white bg-pink-200 h-16 p-4 flex flex-row items-center justify-between">
      <div className="float-left flex flex-row x-sm:space-x-4 space-x-8">
        <Link to="/questions" className="">
          الأسئلة المتكررة
        </Link>
        <Link to="/rules" className="">
          شروط و الاحكام
        </Link>
        <Link to="/privacy-policy" className="">
          سياسة الخصوصية
        </Link>
        <Link to="/return" className="">
          سياسة الارجاع
        </Link>
      </div>
      <div className="float-right">
        <p>جميع الحقوق محفوظة لرغده صيرفي © 2021</p>
      </div>
    </div>
  );
};

export default Footer;
