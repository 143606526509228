import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineInstagram, AiFillTwitterCircle } from "react-icons/ai";
import { RiFacebookCircleLine } from "react-icons/ri";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import { FaSnapchat } from "react-icons/fa";
import { Link } from "react-router-dom";
import { addToNewsletter } from "../services/newsletter";
import * as Yup from "yup";
import { toast } from "react-toastify";

const Contact = () => {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
  });

  return (
    <div className="text-center text-white bg-reghda-gray">
      <div className="p-4 flex flex-col-reverse lg:flex-row justify-around">
        <div className="text-center flex-1 md:px-20">
          <div className="flex flex-row items-center justify-center">
            <img
              src="/images/vector-contact.png"
              alt="vector-contact"
              className="w-20 h-14 hidden lg:block vector-contact"
            />
            <h6 className="my-6 x-sm:text-sm md:text-base lg:text-xl 2xl:text-4xl">
              الإشتراك بالنشرة البريدية
            </h6>
            <img
              src="/images/shapes-contact.png"
              alt="shapes-contact"
              className="w-24 h-10 hidden lg:block shapes-contact"
            />
          </div>
          <div class="my-6 flex">
            <input
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              name="newsletter"
              className="border border-2 rounded-l-full px-4 py-2 w-full text-right text-gray-600 x-sm:text-xs lg:text-lg outline-none focus:outline-none"
              type="text"
              placeholder="البريد الالكتروني"
            />
            <button
              onClick={async () => {
                if (validationSchema.isValidSync({ email })) {
                  setLoading(true);
                  const res = await addToNewsletter({
                    email: email,
                    list_name: "Newsletters",
                  });
                  setLoading(false);
                  console.log(res, "🎉");
                  if (res && res.status === 201) {
                    toast("تم تسجيلك في النشرة البريدية بنجاح ✔");
                  } else {
                    toast("هذا البريد الإلكتروني مسجل من قبل");
                  }
                } else {
                  toast("يجب عليك إدخال بريد إلكتروني صحيح");
                }
              }}
              className="text-sm rounded-r-full px-4 py-2 bg-reghda-blue whitespace-no-wrap outline-none focus:outline-none"
            >
              إشتراك
            </button>
          </div>
          <h6 className="my-6 x-sm:text-sm md:text-base lg:text-xl 2xl:text-4xl">
            تابعنا عبر حساباتنا عبر وسائل التواصل الإجتماعي
          </h6>
          <IconContext.Provider
            value={{ size: "2em", style: { color: "#fff" } }}
          >
            <div className="my-6 flex flex-row x-sm:space-x-2 md:space-x-6 items-center justify-center">
              <a
                href="https://maroof.sa/81318"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <img
                  src="/images/footer/maroof.png"
                  className="x-sm:h-7"
                  alt="icon"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCFAakZF7JF7a0PYrvJxukLw
"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <TiSocialYoutubeCircular></TiSocialYoutubeCircular>
              </a>
              <a
                href="https://www.snapchat.com/add/raghdasairafi
"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <FaSnapchat></FaSnapchat>
              </a>

              <a
                href="https://twitter.com/raghdasairafi?lang=ar
"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <AiFillTwitterCircle></AiFillTwitterCircle>
              </a>

              <a
                href="https://www.facebook.com/roro.sirafi
"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <RiFacebookCircleLine></RiFacebookCircleLine>
              </a>
              <a
                href="https://www.instagram.com/ragdasairafi369/"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <AiOutlineInstagram></AiOutlineInstagram>
              </a>
            </div>
          </IconContext.Provider>
          <div className="flex flex-row space-x-4 items-center justify-center mt-6">
            <p>ragdasairafi369@gmail.com</p>
            <p className="x-sm:text-xs md:text-sm lg:text-lg 2xl:text-2xl">
              ايميل خدمة العملاء
            </p>
          </div>
          <div className="flex flex-row space-x-4 items-center justify-center mt-6">
            <p>00966540020724</p>
            <p className="x-sm:text-xs md:text-sm lg:text-lg 2xl:text-2xl">
              رقم خدمة العملاء
            </p>
          </div>
          <a
            href="https://smartinia.com/"
            target="_blank"
            rel="noreferrer"
            className="flex flex-row space-x-4 items-center justify-center mt-6"
          >
            <img
              src="/images/footer/smartinia.png"
              className="x-sm:h-6 sm:h-10"
              alt="icon"
            />
            <p className="x-sm:text-xs md:text-sm lg:text-lg 2xl:text-2xl">
              تم الانشاء بواسطة{" "}
            </p>
          </a>
        </div>
        <div className="my-4 text-center flex-1 md:px-24">
          <div className="font-bold mb-10 x-sm:text-base lg:text-2xl 2xl:text-4xl flex flex-row items-end justify-items-end justify-center">
            <h4>من هي رغده صيرفي</h4>
          </div>
          <div className="x-sm:text-xs md:text-base lg:text-lg 2xl:text-2xl">
            <p className="my-1 text-right tracking-wide">
              خبيرة في التغيير الشخصي وتحسين العلاقات الزوجية ، تساعد كل أنثى
              متزوجة تريد تغيير حياتها للأفضل وتجاوز الماضي وإعادة التوازن
              لجوانب حياتها
            </p>
            <p className="my-1 text-right tracking-wide">
              يثق بها مئات الآلاف من النساء المثقفات الواعيات حول العالم اللاتي
              ساعدتهن بتغيير حياتهن من خلال برامجها العميقة وأيضا ساعدت حياة
              الكثيرات في تغيير حياتهن من خلال روايات تدريبية حقيقية لمشتركاتها
              السابقات بشكل مجاني عن التغيير
            </p>
            {/* <p className="text-left tracking-wide">لقراءة المزيد ..</p> */}
            <p className="mt-4 tracking-wide text-center">
              مركز رغده طلال حمزه صيرفي للتدريب
            </p>

            <div className="flex flex-row space-x-4 items-center justify-center mt-6">
              <img
                src="/images/footer/visa.png"
                className="x-sm:h-6 sm:h-10"
                alt="icon"
              />
              <img
                src="/images/footer/mastercard.png"
                className="x-sm:h-3 sm:h-5"
                alt="icon"
              />
              <img
                src="/images/footer/paypal.png"
                className="x-sm:h-3 sm:h-5"
                alt="icon"
              />
              <img
                src="/images/footer/mada.png"
                className="x-sm:h-3 sm:h-5"
                alt="icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
