import React from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import MyOrders from "./MyOrders";

const ProductDetails = () => {
  return (
    <>
      <Navbar></Navbar>
      <MyOrders />
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
};

export default ProductDetails;
