import React from "react";
import ProductCard from "./ProductCard";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";

const Products = ({ title, id }) => {
  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/store/categories/${id}`,
    fetcher
  );

  return (
    <div className="h-full x-sm:p-4 md:px-20 space-y-8">
      <div className="bg-reghda-blue rounded-lg w-full text-center text-white px-2 py-2 md:text-xl lg:text-2xl 2xl:text-4xl">
        <h1>{title}</h1>
      </div>
      <div className="w-full h-full grid x-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
        {data &&
          data.products.map((product) => (
            <ProductCard product={product}></ProductCard>
          ))}
      </div>
      {/* <div className="flex flex-row space-x-4 items-center justify-end md:text-base 2xl:text-2xl">
        <p className="bg-reghda-blue px-2 rounded-l-2xl text-white cursor-pointer">
          السابق
        </p>
        <p className="text-reghda-blue">1</p>
        <p className="bg-reghda-blue px-2 rounded-r-2xl text-white cursor-pointer">
          التالي
        </p>
      </div> */}
    </div>
  );
};

export default Products;
