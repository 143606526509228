import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { storeContext } from "./../global/store";
import ScrollToTop from "./scrollToTop";
import ProtectedRoute from "./ProtectedRoute";
import Signup from "./../components/Signup";
import Login from "./../components/Login";
import ContactUs from "./../components/contact-us/ContactUs";
import StorePage from "./../components/store-page/Page";
import StoreTypePage from "../components/store-type-page/Page";
import ProductDetails from "./../components/product-details/Page";
import CartPage from "./../components/cart/Page";
import CheckOutPage from "./../components/check-out/Page";
import FavouritePage from "./../components/favourite/Page";
import SearchPage from "./../components/search/Page";
import MyOrders from "./../components/my-orders/Page";
import PrivacyPolicyPage from "./../components/policy-pages/PrivacyPolicy";
import RefundPolicyPage from "./../components/policy-pages/RefundPolicy";
import QuestionsPage from "./../components/policy-pages/Questions";
import ConditionsPage from "../components/policy-pages/Conditions";
import CheckOutVerifyPage from "./../components/check-out-verify/Page";

const App = () => {
  const { store } = useContext(storeContext);

  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Switch>
          {/* <Route
            path="/signup"
            exact
            render={(props) => <Signup {...props}></Signup>}></Route> */}
          <Route
            path="/login"
            exact
            render={(props) => <Login overview {...props}></Login>}
          ></Route>
          <Route
            path="/"
            exact
            render={(props) => <StorePage {...props}></StorePage>}
          ></Route>
          <Route
            path="/contact-us"
            exact
            render={(props) => <ContactUs {...props}></ContactUs>}
          ></Route>
          <Route
            path="/questions"
            exact
            render={(props) => <QuestionsPage {...props}></QuestionsPage>}
          ></Route>
          <Route
            path="/return"
            exact
            render={(props) => <RefundPolicyPage {...props}></RefundPolicyPage>}
          ></Route>
          <Route
            path="/privacy-policy"
            exact
            render={(props) => (
              <PrivacyPolicyPage {...props}></PrivacyPolicyPage>
            )}
          ></Route>
          <Route
            path="/rules"
            exact
            render={(props) => <ConditionsPage {...props}></ConditionsPage>}
          ></Route>
          <Route
            path="/men"
            exact
            render={(props) => (
              <StoreTypePage type="men" {...props}></StoreTypePage>
            )}
          ></Route>
          <Route
            path="/women"
            exact
            render={(props) => (
              <StoreTypePage type="women" {...props}></StoreTypePage>
            )}
          ></Route>
          <Route
            path="/results"
            exact
            render={(props) => <SearchPage {...props}></SearchPage>}
          ></Route>
          <Route
            path="/product/:slug"
            exact
            render={(props) => <ProductDetails {...props}></ProductDetails>}
          ></Route>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/favourite"
            exact
            render={(props) => <FavouritePage {...props}></FavouritePage>}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/cart"
            exact
            render={(props) => <CartPage {...props}></CartPage>}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/check-out"
            exact
            render={(props) => <CheckOutPage {...props}></CheckOutPage>}
          ></ProtectedRoute>
          <Route
            isLogged={store.isLogged}
            path="/check-out/verify"
            exact
            render={(props) => (
              <CheckOutVerifyPage {...props}></CheckOutVerifyPage>
            )}
          ></Route>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/my-orders"
            exact
            render={(props) => <MyOrders {...props}></MyOrders>}
          ></ProtectedRoute>
          <Redirect to="/"></Redirect>
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
