import React from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import ProductCard from "./../store-page/ProductCard";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import { getTheUserFromStorage } from "./../../services/auth";
import { useLocation } from "react-router-dom";

const FavouritePage = () => {
  const { state } = useLocation();
  const results = state.results;

  return (
    <>
      <Navbar></Navbar>
      <div className="h-full x-sm:p-4 md:px-20 space-y-8">
        <div className="bg-reghda-blue rounded-lg w-full text-center text-white px-2 py-2 md:text-xl lg:text-2xl 2xl:text-4xl">
          <h1>نتائج البحث</h1>
        </div>
        <div className="w-full h-full grid x-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
          {results &&
            results.length &&
            results.map((product) => (
              <ProductCard product={product}></ProductCard>
            ))}
        </div>
      </div>
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
};

export default FavouritePage;
