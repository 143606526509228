import React from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import Toolbar from "./../store-page/Toolbar";
import Details from "./Details";

const ProductDetails = () => {
  return (
    <>
      <Navbar></Navbar>
      <Toolbar></Toolbar>
      <Details></Details>
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
};

export default ProductDetails;
