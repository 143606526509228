import React from "react";
import ReactDOM from "react-dom";
import "./styles/scss/main.css";
import "./styles/tailwind.css";
import App from "./routes/App";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import reportWebVitals from "./reportWebVitals";
import StoreProvider from "./global/StoreProvider";

import { initInterceptors } from "./services/refresh";

initInterceptors();

Modal.setAppElement("#root");

ReactDOM.render(
  // <React.StrictMode>
  <StoreProvider>
    <App />
    <ToastContainer bodyClassName={() => "text-right text-gray-500 p-4"} />
  </StoreProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
