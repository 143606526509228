import React, { useState } from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import { IconContext } from "react-icons";
import { RiWhatsappFill } from "react-icons/ri";

const ContactUs = () => {
  const [formState, setFormState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    title: "",
    message: "",
  });
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <div className="h-full text-center p-16 flex flex-col items-center justify-center x-sm:space-y-4 md:space-y-8 2xl:space-y-16 text-gray-600 font-extrabold x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-5xl">
          <h1>يمكنكم التواصل معنا عبر الواتس اب بالضغط على الأيقونة</h1>
          <a
            href="https://wa.me/+966540020724"
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer"
          >
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#55CD6C" } }}
            >
              <RiWhatsappFill></RiWhatsappFill>
            </IconContext.Provider>
          </a>
          <h1>او مراسلتنا مباشرة عبر البريد الالكتروني</h1>
          <form className="flex flex-col w-full px-0 x-sm:space-y-4 md:space-y-6 2xl:space-y-12 text-right md:text-xl 2xl:text-4xl">
            <fieldset className="flex flex-col md:flex-row items-center justify-between x-sm:space-y-3 md:space-x-3 lg:space-x-6 2xl:space-x-12">
              <input
                id="firstname"
                type="text"
                value=""
                placeholder="الإسم"
                class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-3 2xl:p-6 pr-6 rounded-2xl outline-none focus:outline-none"
              />
              <input
                id="lastname"
                type="text"
                value=""
                placeholder="اللقب"
                class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-3 2xl:p-6 pr-6 rounded-2xl outline-none focus:outline-none"
              />
            </fieldset>
            <fieldset className="flex flex-col md:flex-row items-center justify-between x-sm:space-y-3 md:space-x-3 lg:space-x-6 2xl:space-x-12">
              <input
                id="title"
                type="text"
                value=""
                placeholder="عنوان"
                class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-3 2xl:p-6 pr-6 rounded-2xl outline-none focus:outline-none"
              />
              <input
                id="email"
                type="email"
                value=""
                placeholder="البريد الالكتروني"
                class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-3 2xl:p-6 pr-6 rounded-2xl outline-none focus:outline-none"
              />
            </fieldset>
            <fieldset className="flex flex-row items-start justify-end">
              <textarea
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    message: event.target.value,
                  })
                }
                value={formState.message}
                placeholder="الرسالة"
                rows="5"
                id="text"
                type="text"
                class="w-full border-2 border-gray-100  bg-gray-100 text-right p-1 2xl:p-6 pr-6 rounded-2xl outline-none focus:outline-none"
              />
            </fieldset>
            <div className="flex flex-row items-center justify-center">
              <button className="bg-gradient-to-r from-pink-400 to-indigo-300 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl outline-none focus:outline-none">
                ارسال
              </button>
            </div>
          </form>
        </div>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default ContactUs;
