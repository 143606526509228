import Axios from "axios";

export async function addResponseReview({ user, parent, body }) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/reviews/answers/products/`,
      {
        user,
        parent,
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function editResponseReview({ responseId, body }) {
  try {
    const response = await Axios.patch(
      `https://ragdasairafi369.smartiniaclient.com/reviews/answers/products/${responseId}/`,
      {
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function deleteResponseReview(responseId) {
  try {
    const response = await Axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/reviews/answers/products/${responseId}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
